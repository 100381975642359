import select from 'select-dom'

const init = () => {
	const shareButton = select('.share-button')

    if (shareButton) {
        shareButton.addEventListener('click', (e) => {
            if (navigator.share) {
                e.preventDefault()

                navigator.share({
                    title: shareButton.getAttribute('data-title'),
                    url: shareButton.getAttribute('data-url'),
                }).catch(console.error);
            }
        })
    }
}

export default {
	init,
}
