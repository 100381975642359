import select from 'select-dom';
import Swiper, { Navigation, Pagination } from 'swiper/core';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

const slider = select.all('.js-card-slider');

const init = () => {
  if (slider.length) {
    slider.forEach(el => {
      const activeSlide = el.getAttribute('data-active-slide');
      const lastRelatedIndex = el.getAttribute('data-last-related-index');
      const pagination = JSON.parse(el.getAttribute('data-pagination'));

      const swiper = new Swiper(el, {
        initialSlide: activeSlide,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 16,
        speed: 500,
        navigation: {
          nextEl: '.card-slider__next',
          prevEl: '.card-slider__prev'
        },
        pagination: {
          el: '.card-slider__pagination',
          clickable: true,
          renderBullet: index => {
            return (
              `<div class="swiper-pagination-bullet card-slider__button${pagination[index].related ? ' is-related' : ''}${index == lastRelatedIndex ? ' is-last-related' : ''}">${pagination[index].label}</div>`
            );
          }
        },
        breakpoints: {
          1024: {
            allowTouchMove: false,
            spaceBetween: 80,
          }
        }
      });
    });
  }
}

export default { init }
