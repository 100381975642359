// Import our CSS
import '../css/tailwind-base.scss'
import '../css/tailwind-components.scss'
import '../css/app-components.scss'
import '../css/tailwind-utilities.scss'
import '../css/app-utilities.scss'

import accordion from './accordion'
import animations from './animations'
import ctaPriceToggle from './cta-price-toggle'
import filter from './filter'
import 'lazysizes'

import announcement from './announcement'
import glossary from './glossary'
import cardSlider from './card-slider'
import readMore from './read-more'
import share from './share'
import navigation from './navigation'
import navigationScroll from './navigation-scroll'
import objectFitImages from 'object-fit-images'
import ourPeople from './ourPeople'
import stickyCta from './sticky-cta'
import shareLink from './share-link'
import swiper from './swiper'
import videoPlayer from './video-player'
import hubspot from './hubspot'
import ukPopover from './uk-popover'
import testimonialSlider from './testimonial-slider'
// App main

const main = async () => {
	objectFitImages()

	readMore.init()
	share.init()
	navigation.init()
	navigationScroll.init()
	videoPlayer.init()
	filter.init()
	ourPeople.init()
	accordion.init()
	stickyCta.init()
	shareLink.init()
	ctaPriceToggle.init()
	swiper.init()
	announcement.init()
	glossary.init()
	cardSlider.init()
	testimonialSlider.init()
	ukPopover.init()
	hubspot.init()
	animations.init()
}

// Execute async function
main()

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept()
}
