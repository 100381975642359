import axios from 'axios'

export const fetchIp = async () => {
	try {
		const { data, error } = await axios.get(
			'https://api.ipstack.com/check?access_key=5cb5c5fbdc1ef5eccb24d39fcbaf0aef'
		)
		if (error) {
			return console.log(error)
		}

		return data
	} catch (error) {
		console.log(error)
	}
}
