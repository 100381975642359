//import lottie from 'lottie-web'
import scrollama from 'scrollama'

const init = () => {
	const animationList = document.querySelectorAll('.js-lottie-animation')
	if (animationList.length) {

		const lottieEvent = new Event('lottieLoaded');

		var addLottie = document.createElement("script");
    	addLottie.async = "true";
    	addLottie.onload = function() {document.body.dispatchEvent(lottieEvent)};
    	addLottie.setAttribute("src","https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.7.7/lottie.min.js");
    	document.head.appendChild(addLottie);

		// Listen for the event.
		document.body.addEventListener('lottieLoaded', function (e) {
			const scroller = scrollama()

			animationList.forEach((element) => {
				let singleAnimation = lottie.loadAnimation({
					container: element,
					renderer: 'svg',
					autoplay: true,
					loop: element.dataset.loop ? true : false,
					name: element.dataset.animation,
					path: element.dataset.animationPath 
						? element.dataset.animationPath 
						: `/assets/animations/${element.dataset.animation}.json`,
				})

				singleAnimation.addEventListener('complete', () => {
					singleAnimation.goToAndStop(0)
				})
			})

			scroller
				.setup({
					step: animationList,
				})
				.onStepEnter((response) => {
					lottie.play(response.element.dataset.animation)
				})
			});
	}
}

export default { init }
