import select from 'select-dom'
import Cookies from 'js-cookie'

const popover = select('#uk-popover')

const setCookie = (e) => {
	Cookies.set('uk_popover_hidden', 'true')
    popover.classList.remove('show')
    window.setTimeout(() => popover.classList.add('hidden'), 300)
	e.preventDefault()
}

const conditionalGaEvent = (name, label, desc) => {
	var ga = window[window['GoogleAnalyticsObject'] || 'ga']
	if (typeof ga == 'function') {
		ga('send', 'event', name, label, desc)
	} else {
		console.log('GA event fired: ', name, label, desc)
	}
}

const init = () => {
	if (document.getElementById('uk-cta-block')) {
		conditionalGaEvent('UK Altus', 'CTA', 'CTA shown')

		const ukCtaButton = select.all('#uk-cta-block a')
		ukCtaButton.forEach((el) => {
			el.addEventListener('click', () => {
				conditionalGaEvent('UK Altus', 'CTA', 'CTA link clicked')
			})
		})
	}
    if (document.cookie.indexOf('uk_popover_hidden=') === -1 && document.getElementById('uk-popover')) {
        popover.classList.remove('hidden')
        window.setTimeout(() => popover.classList.add('show'), 100)

		conditionalGaEvent('UK Altus', 'Popover Shown', 'Popover shown')

		const closeBtn = select('#uk-popover-close')
		closeBtn.addEventListener('click', (e) => {
			setCookie(e)
			conditionalGaEvent('UK Altus', 'Popover Close', 'Close button pressed')
		})

		const xBtn = select('#uk-popover-x')
		xBtn.addEventListener('click', (e) => {
			setCookie(e)
			conditionalGaEvent('UK Altus', 'Popover Close', 'X button pressed')
		})

		const inside = select('#uk-popover-inside')
		inside.addEventListener('click', (e) => {
			e.stopPropagation()
		})

		popover.addEventListener('click', (e) => {
			setCookie(e)
			conditionalGaEvent('UK Altus', 'Popover Close', 'Background pressed')
		})

		const cta = select('#uk-popover-cta')
		cta.addEventListener('click', (e) => {
			Cookies.set('uk_popover_hidden', 'true')
			conditionalGaEvent('UK Altus', 'Popover Clicked', 'Clicked CTA in popover')
		})
	}
}

export default { init }
