import select from 'select-dom'
import scrollama from 'scrollama'

const init = () => {
	// look if sticky cta appears on the page
	const stickyButton = select('.btn-sticky')

	if (!stickyButton) {
		return
	}

	// read the data-target attribute
	// if target existis then use it
	// otherwise use stickey wrapper itself
	let target = stickyButton.dataset.target || '#sticky-target'

	const scroller = scrollama()
	scroller
		.setup({
			step: target,
		})
		.onStepEnter((response) => {
			stickyButton.classList.toggle('is-sticky')
		})
}

export default {
	init,
}
