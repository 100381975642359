import select from 'select-dom'
import Accordion from '@accede-web/accordion'

const init = () => {
	var accordionList = select.all('.js-accordion')

	accordionList.forEach((element) => {
		const accordion = new Accordion(element)

		accordion.on('show', function (header, panel) {
			accordion.open(panel)
			if (header.nextElementSibling) {
				header.nextElementSibling.style.transform =
				'rotate(180deg) translateY(50%)'
			}
		})

		accordion.on('hide', function (header, panel) {
			accordion.close(panel)
			if (header.nextElementSibling) {
				header.nextElementSibling.style = null
			}
		})

		accordion.mount()
	})
}

export default { init }
