import select from 'select-dom'
import stickybits from 'stickybits'
import updwn from 'updwn'
import scrollama from 'scrollama'

const init = () => {
	const header = select('#header')
	const body = select('body')
	if (header) {
		stickybits(header, { useFixed: true, useStickyClasses: true })
		window.addEventListener('scroll', (info) => {
			if (window.pageYOffset === 0) {
				header.firstElementChild.classList.remove('has-background')
			}
		})
	}

	const navBgTrigger = select('#js-nav-bg-trigger')

	if (!navBgTrigger) {
		console.log('nav trigger not found')
		// cancel if trigger for scroll based bg reveal isn't there (i.e. 404 and People page)
		return
	}

	const scroll = updwn({ speed: 50 })
	scroll.up(() => {
		body.classList.add('is-visible-nav')
	})
	scroll.down(() => {
		body.classList.remove('is-visible-nav')
	})

	const scroller = scrollama()

	// setup the instance, pass callback functions
	scroller
		.setup({
			step: navBgTrigger,
		})
		.onStepEnter(({ direction }) => {
			if (direction === 'down') {
				header.firstElementChild.classList.add('has-background')
			}
		})

	// setup resize event
	window.addEventListener('resize', scroller.resize)
}

export default { init }
