import select from 'select-dom'
import Cookies from 'js-cookie'

const setCookie = () => {
	Cookies.set('announcement', 'disabled')
}

const conditionalGaEvent = (name, label, desc) => {
	var ga = window[window['GoogleAnalyticsObject'] || 'ga']
	if (typeof ga == 'function') {
		ga('send', 'event', name, label, desc)
	} else {
		console.log('GA event fired: ', name, label, desc)
	}
}

const addHandler = (container) => {
	const closeBtn = select('#js-announcement-close')
	closeBtn.addEventListener('click', () => {
		setCookie()
		select('body').classList.remove('banner')
		container.remove()
	})

	const ukCloseBtn = select.all('.uk-js-announcement-close', container)
	ukCloseBtn.forEach((el) => {
		conditionalGaEvent('UK Altus', 'Annoucement', 'Announcement shown')
		el.addEventListener('click', () => {
			conditionalGaEvent('UK Altus', 'Annoucement', 'Announcement closed')
		})
	})

	const links = select.all('a', container)
	links.forEach((el) => {
		el.addEventListener('click', (e) => {
			e.preventDefault()
			setCookie()
			window.location = e.target.href
		})
	})
}

const init = () => {
	const container = select('#js-announcement')

	if (container) {
		addHandler(container)
	}
}

export default { init }
