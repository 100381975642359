//import mixitup from 'mixitup'
import select from 'select-dom'

const init = () => {
	const filterContainer = select('#filter-container')
	const selects = select.all('.js-people-filter')
	const noItemsFoundMessage = select('#no-items-found-message')

	if (filterContainer) {
		const mixer = mixitup(filterContainer, {
			animation: {
				duration: 250,
				nudge: true,
				reverseOut: false,
				effects: 'fade translateZ(-100px)',
			},
			callbacks: {
				onMixEnd: function (state) {
					if (state.hasFailed) {
						noItemsFoundMessage.style.display = 'block'
					} else {
						noItemsFoundMessage.style.display = 'none'
					}
				},
			},
		})

		function getAllValues(arr) {
			return arr.reduce((accum, current, index) => accum + current.value, '')
		}
	
		selects.forEach((element) => {
			element.addEventListener('change', (event) => {
				const filterBy = getAllValues(selects)
				mixer.filter(filterBy || 'all')
			})
		})
	}
}

export default { init }
