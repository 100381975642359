import select from 'select-dom'

const links = select.all('.js-share-link');

const init = async () => {
	if (links.length) {
        links.forEach(el => {
            el.addEventListener("click", async (e) => {
                e.preventDefault();

                if (navigator.share) {
                    navigator.share({
                        title: el.dataset.title,
                        url: el.dataset.url,
                    });
                } else {
                    navigator.clipboard.writeText(el.dataset.url);
                    select.last('.js-share-link-copied').classList.remove('hidden');
                }
            });
        });
    }
}

export default {
	init,
}
