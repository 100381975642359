import select from 'select-dom'
import Swiper, { Autoplay } from 'swiper/core'

const init = () => {
	const swiperArr = select.all('.swiper-container')

	Swiper.use([Autoplay])

	swiperArr.forEach((element) => {
		const swiper = new Swiper(element, {
			loop: true,
			speed: 3000,
			spaceBetween: 30,
			allowTouchMove: false,
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
			},
			slidesPerView: 2,
			breakpoints: {
				420: {
					slidesPerView: 3,
				},
			},
		})
	})
}

export default { init }
