import select from 'select-dom'

window._wq = window._wq || []

const videoHandler = (video) => {
	if (!video.src) {
		const id = video.dataset.cookieblockSrc.split('iframe/')[1]
		const imageContainer = select(`#image-container-${CSS.escape(id)}`)
		const optoutMessage = select(`#optout-${CSS.escape(id)}`)
		imageContainer.classList.add('video-blocked')
		optoutMessage.classList.remove('hidden')
		return
	}

	const id = video.src.split('iframe/')[1]
	const imageContainer = select(`#image-container-${CSS.escape(id)}`)

	_wq.push({
		id: id.slice(0, -1),
		onReady: (video) => {
			imageContainer.addEventListener('click', () => {
				imageContainer.classList.add('video-playing')
				video.play()
			})
		},
	})
}

const init = () => {
	const allVideoSources = select.all('.media-module__iframe')

	if (allVideoSources.length) {

		const wistiaEvent = new Event('wistiaLoaded');

		var addWistia = document.createElement("script");
		addWistia.async = "true";
		addWistia.onload = function() {document.body.dispatchEvent(wistiaEvent)};
		addWistia.setAttribute("src","https://fast.wistia.com/assets/external/E-v1.js");
		document.head.appendChild(addWistia);

		// Listen for the event.
		document.body.addEventListener('wistiaLoaded', function (e) {

			allVideoSources.forEach((video) => {
				videoHandler(video)
			})

		})
	}
}

export default {
	init,
}
