import select from 'select-dom'
import scrollama from 'scrollama'

const init = () => {
	const scroller = scrollama();
    scroller.setup({
        container: '.glossary-container',
        step: '.glossary-step',
        offset: '120px',
    }).onStepEnter((response) => {
        const letter = response.element.dataset.letter;
        document.querySelectorAll('.glossary-nav-item').forEach(node => node.classList.remove('active'));

        const firstLetter = document.querySelector(".glossary-nav-item[data-letter-first='" + letter + "']");
        if (firstLetter) {
            firstLetter.classList.add('active');
        }

        const lastLetter = document.querySelector(".glossary-nav-item[data-letter-last='" + letter + "']");
        if (lastLetter) {
            lastLetter.classList.add('active');
        }

        const glossaryNav = document.querySelector('.glossary-nav');
        const glossaryItem = select('.glossary-nav-item.active');
        let x = glossaryItem.offsetLeft;
        glossaryNav.scrollTo({left: x, behavior: 'smooth'});
    })

    const glossaryNavItem = document.querySelectorAll('.glossary-nav-item')
    if (glossaryNavItem) {
        glossaryNavItem.forEach(node => node.addEventListener('click', (e) => {
            e.preventDefault()
            const glossaryItem = select('#glossary-' + e.target.dataset.letterFirst);
            let y = glossaryItem.getBoundingClientRect().top + window.pageYOffset - 120;
            window.scrollTo({top: y, behavior: 'smooth'})
        }))
    }
}

export default { init }
