import select from 'select-dom';
import Swiper, { Navigation, EffectFade } from 'swiper/core';

// configure Swiper to use modules
Swiper.use([Navigation]);

const slider = select.all('.js-testimonial-slider');

const init = () => {
  if (slider.length) {
    slider.forEach(el => {
      const activeSlide = el.getAttribute('data-active-slide');

      const swiper = new Swiper(el, {
        initialSlide: activeSlide,
        centeredSlides: true,
        slidesPerView: 'auto',
        spaceBetween: 16,
        speed: 500,
        effect: 'fade',
        loop: true,
        allowTouchMove: false,
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
          delay: 4000,
        },
        breakpoints: {
          1024: {
            spaceBetween: 80,
          }
        }
      });
    });
  }
}

export default { init }
