import select from 'select-dom'
import { fetchIp } from './util'

const cadPricing = select('.pricing-cad')
const usdPricing = select('.pricing-usd')

const init = async () => {
	if (cadPricing && usdPricing) {
		const userDetails = await fetchIp()
		togglePricing(userDetails.country_code)
	}
}

const togglePricing = (countryCode) => {
	if (countryCode == 'CA') {
		usdPricing.classList.add('hidden')
		cadPricing.classList.remove('hidden')
	}
}

export default {
	init,
}
