import select from 'select-dom'
import { fetchIp } from './util'
import nanoajax from 'nanoajax'

const init = async () => {
	function waitFor(predicate, timeout) {
		return new Promise((resolve, reject) => {
			const check = () => {
				if (!predicate()) return;
					clearInterval(interval);
					resolve();
			};
			const interval = setInterval(check, 100);
			check();

			if (!timeout) return;
				setTimeout(() => {
				clearInterval(interval);
				reject();
			}, timeout);
		});
	};
	try {
		await waitFor(() => window.hasOwnProperty('optIn'), 2000);
	} catch (err) {
		window.optIn = [];
	}

	const formList = select.all('.js-hubspot-form')

	let optIn = false

	if (formList) {
		const userDetails = await fetchIp()
		if (
			window.optIn[userDetails.country_code] ||
			window.optIn[`${userDetails.country_code}-${userDetails.region_code}`]
		) {
			optIn = true
		}

		const hubspotEvent = new Event('hubspotLoaded');

		var addHubspot = document.createElement("script");
    	addHubspot.async = "true";
    	addHubspot.onload = function() {document.body.dispatchEvent(hubspotEvent)};
		addHubspot.setAttribute("data-cookieconsent","ignore");
    	addHubspot.setAttribute("src","//js.hsforms.net/forms/v2.js");
    	document.head.appendChild(addHubspot);

		// Listen for the event.
		document.body.addEventListener('hubspotLoaded', function (e) {

			formList.forEach((element) => {
		
				if (typeof element.dataset.connect != 'undefined') {
					const postUrl = element.dataset.postUrl
	
					var script = document.createElement('script')
					script.type = 'text/javascript'
					script.crossOrigin = 'anonymous'
					script.integrity =
						'sha256-u7e5khyithlIdTpu22PHhENmPcRdFiHRjhAuHcs05RI='
					script.src = 'https://code.jquery.com/jquery-3.6.0.slim.min.js'
	
					document.getElementsByTagName('head')[0].appendChild(script)
					window.hbspt.forms.create({
						portalId: element.dataset.portalId,
						formId:
							element.dataset.optIn && optIn
								? element.dataset.optIn
								: element.id.slice(6, element.id.length),
						target: `#${element.id}`,
						onFormSubmit: function ($form) {
							var data = {
								firstname: $form.find('input[name=firstname]').val(),
								lastname: $form.find('input[name=lastname]').val(),
								email: $form.find('input[name=email]').val(),
								phone: $form.find('input[name=phone]').val(),
								lang: $('html').attr('lang'),
							}
	
							nanoajax.ajax(
								{
									url: postUrl,
									headers: {
										'Content-Type': 'application/json; charset=utf-8',
									},
									method: 'POST',
									body: JSON.stringify(data),
								},
								(code, responseText) => {
									if (code !== 200) {
										select('.submitted-message').textContent =
											element.dataset.errorMessage
										return
									}
								}
							)
						},
					})
				} else {
					window.hbspt.forms.create({
						portalId: element.dataset.portalId,
						formId:
							element.dataset.optIn && optIn
								? element.dataset.optIn
								: element.id.slice(6, element.id.length),
						target: `#${element.id}`,
					})
				}
				
			});
		})
	}
}

export default { init }
