import select from 'select-dom'
import 'url-search-params-polyfill'

const init = () => {
	const elements = select.all('.js-filter')
	if (elements.length) {
		elements.forEach((el) => {
			el.addEventListener('change', (ev) => {
				let name = ev.target.name
				let value = ev.target.value
				let searchParams = new URLSearchParams(window.location.search)
				if (name === value) {
					searchParams.delete(name)
				} else {
					searchParams.set(name, value)
				}
				window.location = `${
					window.location.pathname
				}?${searchParams.toString()}#filter`
			})
		})
	}
}

export default { init }
