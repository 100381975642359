import select from 'select-dom'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import throttle from 'lodash/throttle'
import Cookies from 'js-cookie'

const html = select('html')
const header = select('.header') || select('#app')
const stickyNav = select('.js-sticky-nav')
const globalNav = select('.global-nav')
const globalNavInner = select('.global-nav__inner')
const dropdownContainer = select('.header__dropdown')
const dropdownBackground = select('.header__dropdown-background')
const mobileToggles = select.all('.js-nav-toggle')
const mobileCta = select('.global-nav__mobile-cta')
const dropdownToggles = select.all('.global-nav__dropdown-toggle')
const dropdownMenus = select.all('.global-nav__dropdown')
const indicatorTrack = select('.global-nav__indicator-track')
const indicator = select('.global-nav__indicator')
const navScrim = select('.site-scrim')
const openDropdownClass = 'has-nav-open'
const openMenuClass = 'has-mobile-nav-open'
const visibleHeaderClass = 'has-visible-header'
const activeClass = 'is-active'
const openLanguageClass = 'language-selector-open'
const languageToggle = select('.js-language-toggle')
const languageList = select('.global-nav__language-list')
const languageListLinks = select.all('.global-nav__language-option')
const hero = select('.hero')
const headerHeight = header.clientHeight

let isOpenDropdown = false
let isOpenMenu = false
let currentHandle = null
let dropdownHeight = null
let dropdownBackgroundPosition = null
let prevScrollpos = window.pageYOffset
let languageToggleOpen = false

const setMobileMenuPosition = () => {
	const dropdownDistance = stickyNav.offsetTop + stickyNav.clientHeight
	const mobileMenuOffset = stickyNav.classList.contains('is-sticky')
		? headerHeight
		: headerHeight // Handle height of mobile menu and mobile cta button position

	globalNav.style.top = dropdownDistance + 'px'
	globalNavInner.style.paddingBottom = mobileMenuOffset + 'px'
	mobileCta.style.bottom = mobileMenuOffset + 'px'
}

const setDropdownBackgroundPosition = (handle) => {
	const target = document.querySelector(
		'#' + handle + ' .global-nav__section--featured'
	)

	if (!isOpenDropdown) {
		header.setAttribute('data-transition', false)
	} else {
		header.setAttribute('data-transition', true)
	}

	dropdownBackgroundPosition = target
		? dropdownContainer.clientWidth - target.getBoundingClientRect().left
		: 0
	dropdownBackground.style.transform =
		'translateX(-' + dropdownBackgroundPosition + 'px)'
}

const openDropdownContainer = (handle = null) => {
	if (!handle) return false

	isOpenDropdown = true
	currentHandle = handle
	html.classList.add(openDropdownClass)
}

const closeDropdownContainer = () => {
	isOpenDropdown = false
	currentHandle = null
	dropdownContainer.style.transform = ''
	html.classList.remove(openDropdownClass)
}

const openLanguageMenu = () => {
	languageToggleOpen = true
	languageList.previousElementSibling.classList.add(openLanguageClass)
	languageList.classList.add(openLanguageClass)
}

const closeLanguageMenu = () => {
	languageToggleOpen = false
	languageList.previousElementSibling.classList.remove(openLanguageClass)
	languageList.classList.remove(openLanguageClass)
}

const toggleDropdownMenu = (handle) => {
	dropdownMenus.forEach((menu) => {
		const toggleElement = menu.previousElementSibling

		if (menu.id === handle) {
			if (languageToggleOpen) {
				closeLanguageMenu()
			}
			dropdownHeight = headerHeight + menu.clientHeight

			toggleElement.setAttribute('aria-expanded', true)
			dropdownContainer.style.transform =
				'translateY(' + dropdownHeight + 'px)'
			menu.classList.add(activeClass)
		} else {
			toggleElement.setAttribute('aria-expanded', false)
			menu.classList.remove(activeClass)
		}
	})
}

const toggleIndicatorHandler = (handle) => {
	const toggle = select('[data-handle=' + handle + ']')

	if (handle != 'search' && toggle) {
		const toggleWidth = toggle.offsetWidth
		const scale = toggleWidth / 100
		const parent = select('.js-indicator-parent')
		const parentPosition = parent.getBoundingClientRect().left
		const toggleOffset = toggle.getBoundingClientRect().left - parentPosition

		Object.assign(indicatorTrack.style, {
			transform: 'translateX(' + toggleOffset + 'px)',
		})
		Object.assign(indicator.style, { transform: 'scaleX(' + scale + ')' })
	} else {
		Object.assign(indicator.style, { transform: 'scaleX(0)' })
	}
}

const openMobileMenu = () => {
	html.classList.add(openMenuClass)
	disableBodyScroll('.global-nav__inner')
}

const closeMobileMenu = () => {
	html.classList.remove(openMenuClass)
	closeDropdownContainer()
	toggleDropdownMenu(currentHandle)
	enableBodyScroll('.global-nav__inner')
}

const dropdownNavHandler = (event) => {
	let handle = null

	event.preventDefault()

	if (event.target.dataset.handle) {
		handle = event.target.dataset.handle
	} else {
		handle = event.target.closest('.global-nav__dropdown-toggle').dataset
			.handle
	}

	// Set the position of the dropdown background before opening of dropdown
	if (handle) {
		setDropdownBackgroundPosition(handle)
	}

	// Handle dropdown container
	if (handle === currentHandle) {
		closeDropdownContainer()
	} else {
		openDropdownContainer(handle)
	}

	// Handle dropdown menus
	toggleDropdownMenu(currentHandle)

	// Handle active toggle indicator
	toggleIndicatorHandler(currentHandle)

	if (html.classList.contains(openMenuClass)) {
		const el = event.currentTarget

		if (el.getAttribute('aria-expanded') == 'true') {
			globalNavInner.scroll({
				top: el.offsetTop + 1,
				behavior: 'smooth',
			})
		} else {
			globalNavInner.scroll({
				top: 0,
				behavior: 'smooth',
			})
		}
	}
}

const mobileNavHandler = () => {
	if (isOpenMenu) {
		isOpenMenu = false
		closeMobileMenu()
	} else {
		isOpenMenu = true
		openMobileMenu()
	}
}

const outsideClickListener = (event) => {
	const clickWithinAnyMenu = dropdownMenus.some((menu) =>
		menu.contains(event.target)
	)

	const clickOnLanguageToggle = languageToggle
		? languageToggle.contains(event.target)
		: undefined

	if (
		!clickWithinAnyMenu &&
		!header.contains(event.target) &&
		isOpenDropdown
	) {
		closeDropdownContainer()
		toggleDropdownMenu(currentHandle)
	}

	if (typeof clickOnLanguageToggle == 'undefined') {
		// break if the language toggle is not visible
		return
	}

	if (!clickOnLanguageToggle) {
		closeLanguageMenu()
	}
}

const onEscHandler = (event) => {
	const key = event.key

	if (isOpenDropdown && key == 'Escape') {
		closeDropdownContainer()
		toggleDropdownMenu(currentHandle)
	}
}

const onWindowWidthResizeHandler = () => {
	if (!isOpenDropdown) return false

	setDropdownBackgroundPosition(currentHandle)
}

const handleLanguageToggle = () => {
	if (languageToggleOpen) {
		closeLanguageMenu()
	} else {
		if (isOpenDropdown) {
			closeDropdownContainer()
			toggleDropdownMenu(currentHandle)
		}
		openLanguageMenu()
	}
}

const init = function () {
	dropdownToggles.forEach((toggle) => {
		toggle.addEventListener('click', dropdownNavHandler)
	})

	mobileToggles.forEach((toggle) => {
		toggle.addEventListener('click', mobileNavHandler)
	})

	if (navScrim) {
		navScrim.addEventListener('click', () => {
			closeDropdownContainer()
			toggleDropdownMenu(currentHandle)
		})
	}

	document.addEventListener('click', outsideClickListener)
	if (languageToggle) {
		languageToggle.addEventListener('click', handleLanguageToggle)
	}
	document.addEventListener('keydown', onEscHandler)
	window.addEventListener('resize', onWindowWidthResizeHandler)

	if (languageListLinks) {
		languageListLinks.forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault()
				Cookies.set('language', 'navigated')
				window.location = e.target.href
			})
		})
	}
}

export default {
	init,
	closeMobileMenu,
}
