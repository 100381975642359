import select from 'select-dom'

const init = () => {
	const readMore = select('.read-more')
    const readMoreButton = select('.read-more-button')

    if (readMoreButton) {
        readMoreButton.addEventListener('click', (e) => {
            e.preventDefault()
            readMore.classList.toggle('max-h-lg')
            readMore.classList.toggle('read-more')

            if (readMoreButton.innerText == 'Read Less') {
                readMoreButton.innerText = 'Read More'
                let y = readMore.getBoundingClientRect().top + window.pageYOffset - 100;
                window.scrollTo({top: y, behavior: 'smooth'})
            } else {
                readMoreButton.innerText = 'Read Less'
            }
        })
    }
}

export default {
	init,
}
